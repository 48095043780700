<template>
  <HandleDataForms v-bind:apiSource="mongo" title="Puntajes" collection="simulacion_puntaje" v-bind:dataSource="'/' + database" templateTable="TablePuntajesForm" templateTableModal="TablePuntajesModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDateISO = computed(() => moment().startOf('week').format())
    const endDateISO = computed(() => moment().endOf('day').format())

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        tipo_documento: 'Tipo documento',
        numero_documento: 'Número de documento',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido Paterno',
        email: 'E-mail',
        codigo_area: 'Código de area',
        celular: 'Celular',
        ano_egreso: 'Año de egreso',
        ano_cursado: 'Año cursado',
        carrera: 'Carrera ID',
        carrera_nombre: 'Carrera',
        sede: 'Sede ID',
        sede_nombre: 'Sede',
        puntaje_nem: 'NEM',
        puntaje_ranking: 'Ranking',
        puntaje_psu_lenguaje: 'Lenguaje',
        puntaje_psu_matematicas: 'Matematica',
        puntaje_paes_matematicas_2: 'Matematica 2',
        puntaje_psu_historia: 'Historia',
        puntaje_psu_ciencias: 'Ciencias',
        pondearcion: 'Pondearción',
        utm_source: 'Fuente UTM',
        utm_medium: 'Medio UTM',
        utm_campaign: 'Campaña UTM',
      },
      query: {
        collection: 'puntajes',
        date_field: 'createdAt',
        fecha_inicio: startDateISO.value,
        fecha_fin: endDateISO.value,
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
